import { formatPhoneNumber } from "./formatters";

const phoneChars = ["(", ")", " ", "-"];
const isPhoneNumberStart = (char: any) =>
  !isNaN(char) || phoneChars.includes(char);
const isPhone = (value: string) => {
  return (
    isPhoneNumberComplete(value) || value.split("").every(isPhoneNumberStart)
  );
};

export const emailPattern =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateInput = (value: string = "") => {
  if (value.length >= 2 && isPhone(value)) {
    const formattedPhone = formatPhoneNumber(value);
    return { type: "phone", value: formattedPhone };
  }

  return { type: "email", value };
};

export function isPhoneNumberComplete(value: string) {
  const digits = value.replace(/\D/g, "");
  return digits.length === 10;
}
