export const formatPhoneNumber: (input: string) => string = (input) => {
  const digits = input.replace(/\D/g, "");
  if (digits.length < 4) return digits;
  if (digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;

  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};

export const toE164 = (phoneNumber: string, countryCode = "1") => {
  const digits = phoneNumber.replace(/\D/g, "");

  // Convert to E.164 format
  return `+${countryCode}${digits}`;
};
