export const defaultSteps = [
  { label: "basic info", path: "/basic-info" },
  { label: "purchase plan", path: "/purchase-plan" },
  { label: "create account", path: "/create-account" },
  { label: "install & activate SIM", path: "/install-esim" },
];

export const stepPaths = [
  "/basic-info",
  "/privacy",
  "/create-account",
  "/purchase-plan",
];
