import React, { useEffect, useState, type ReactNode } from "react";
import SubscriptionDataContext, {
  SUBS_INIT_DATA,
  type SubscriptionFlowData,
} from "./SubscriptionDataContext";
import axios from "axios";
import { captureMessage } from "@sentry/react";
const AUTH_URL = import.meta.env.VITE_AUTH_URL;

const initialNavigation = {
  goBack: () => {
    window.history.back();
  },
};

const getInitialData = () => {
  const sessionData = sessionStorage.getItem("data");

  if (sessionData) return JSON.parse(sessionData) as SubscriptionFlowData;

  return SUBS_INIT_DATA;
};

const initialData = getInitialData();
const SubscriptionDataProvider = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement | null => {
  const [data, setData] = useState<SubscriptionFlowData>(initialData);
  const [plans, setPlans] = useState<Plan[]>();
  const [navigation, setNavigation] = useState(initialNavigation);

  useEffect(() => {
    const fetchGeoData = async () => {
      if (data.zipcode) return;

      try {
        const res = await axios.get(`${AUTH_URL}/geo`);
        const { country, ...geoData } = res.data;

        if (country !== "US") {
          captureMessage("customer outside of US", {
            level: "log",
            tags: {
              geo: true,
            },
            user: { email: data.email || data.phone_number },
            extra: {
              country,
            },
          });

          // Set default zipcode for customers outside the US
          addData({ zipcode: "78701" });
        } else {
          // Set geo data for US customers
          addData({ ...geoData, zipcode: geoData.zipcode || "78701" });
        }
      } catch (error: any) {
        captureMessage("geolocation error", {
          level: "warning",
          tags: {
            geo: true,
          },
          user: { email: data.email || data.phone_number },
          extra: {
            error: error.message,
          },
        });

        // Default zipcode if error occurs
        addData({ zipcode: "78701" });
      }
    };

    fetchGeoData();
  }, [data.zipcode]);

  const addData = (newData: Partial<SubscriptionFlowData>) => {
    setData((prevData) => {
      const updatedData = { ...(prevData ?? {}), ...newData };
      sessionStorage.setItem("data", JSON.stringify(updatedData));
      return updatedData;
    });
  };

  const setGoBack = (fn: () => void): void => {
    setNavigation((prevNavigation) => {
      return {
        ...prevNavigation,
        goBack: fn,
      };
    });
  };

  return data === null ? null : (
    <SubscriptionDataContext.Provider
      value={{
        data,
        plans: plans as unknown as Plan[],
        setPlans,
        addData,
        navigation,
        setGoBack,
      }}
    >
      {children}
    </SubscriptionDataContext.Provider>
  );
};

export default SubscriptionDataProvider;
