import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { useForm, Controller } from "react-hook-form";
import SubscriptionDataContext from "@context/SubscriptionDataContext";
import {
  emailPattern,
  isPhoneNumberComplete,
  validateInput,
} from "@utils/validations";
import PhoneOrEmailAuth from "./PhoneOrEmailAuth";
import withRedirectOnMissingData from "@components/withRedirectOnMissingData";
import { Button } from "@components/commons/Button";
import { useNavigate } from "react-router-dom";

const FlexItem = tw.div`flex flex-col w-full lg:w-[50%]`;
const Title = tw.div`text-[34px] font-light leading-none mb-[30px]`;

const Container = tw.div`min-h-[100%] px-[5%]`;
const Content = tw.div`max-w-1200 mx-auto pt-[20px] lg:pt-[100px] pb-[150px]`;

const rules = {
  required: "this field is required.",
  validate: (value: string) => {
    const { type } = validateInput(value);
    if (type === "phone") {
      return isPhoneNumberComplete(value) || "phone number not compeleted";
    }

    return emailPattern.test(value) || "incorrect email address";
  },
};

const CreateAccount: React.FC = () => {
  const navigate = useNavigate();
  const { data, addData } = useContext(SubscriptionDataContext);
  const [verified, setVerified] = useState(data.verified);

  const { control, clearErrors } = useForm<{
    phoneOrEmail: string;
  }>({
    defaultValues: {
      phoneOrEmail: data.email || data.phone_number || "",
    },
  });

  useEffect(() => {
    addData({ verified });
  }, [verified]);

  return (
    <Container>
      <Content>
        <FlexItem>
          <Title>create your account</Title>
          <Controller
            name="phoneOrEmail"
            control={control}
            rules={rules}
            render={(form) => {
              const {
                field,
                fieldState: { error },
              } = form;
              const { onChange, name, value } = field;

              return (
                <PhoneOrEmailAuth
                  value={value}
                  name={name}
                  error={error}
                  clearErrors={clearErrors}
                  onChange={({ type, value }: any) => {
                    addData({
                      phone_number: type === "phone" ? value : "",
                      email: type === "email" ? value : "",
                    });
                    onChange(value);
                  }}
                  verified={verified}
                  setVerified={setVerified}
                />
              );
            }}
          />
          {verified && (
            <Button
              onClick={() => navigate("/purchase-plan")}
              title="continue"
              type="active"
              className="font-[200] lg:w-[406px] w-full mt-5"
            />
          )}
        </FlexItem>
      </Content>
    </Container>
  );
};

export default withRedirectOnMissingData(CreateAccount);
