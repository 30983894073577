export const isGA4Available = window?.dataLayer;

export const sendGAPageEvent: (pathname: string) => void = (pathname) => {
  sendGAEvent("virtualPageview", {
    pageUrl: pathname,
    pageTitle: pathname,
  });
};

export const sendGADeviceSelected: (data: GAEventData) => void = (data) => {
  sendGAEvent("basic_info_device_selected", data);
};

export const sendGASelectNumber: (data: GAEventData) => void = (data) => {
  sendGAEvent("basic_info_select_number", data);
};

export const sendGALastBasicInfo: (data: GAEventData) => void = (data) => {
  sendGAEvent("basic_info_end", data);
};

export const sendGAEvent: GAEvent = (event, data) => {
  window?.dataLayer?.push({
    event,
    ...data,
  });
};
